import React from 'react';
import './footer.css'; // Link to your CSS file

const Footer = () => {
    return (
        <footer>
            <div className="container">
            <ul className="social">
    <li><a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a></li>
    <li><a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
    <li><a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
    <li><a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></li>
</ul>
                <p>© 2024 SEO Agency. All Rights Reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
