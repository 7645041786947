import React, { useState } from 'react';
import './header.css';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header>
            <div className="container">
                <div className="logo">
                    <h1>Doing The SEO</h1>
                </div>
                <nav>
                    <div className="menu-toggle" onClick={toggleMenu}>
                        &#9776;
                    </div>
                    <ul className={menuOpen ? 'active' : ''}>
                        <li><a href="/">Home</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/about">About Us</a></li>
                        <li><a href="/contact">Contact</a></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;
