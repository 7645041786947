// src/App.js
import React from 'react';
import './App.css';
import Header from './components/header';
import Hero from './components/hero';
import Services from './components/services';
import Footer from './components/footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Services />
      <Footer />
    </div>
  );
}

export default App;