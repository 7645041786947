import React from 'react';
import './services.css';

const Services = () => {
  return (
    <section className="services" id="services">
      <div className="container">
        <h2>Our SEO Services</h2>
        <div className="service-grid">
          <div className="service">
            <h3>Local SEO</h3>
            <p>Improve your local search ranking in London & Manchester.</p>
          </div>
          <div className="service">
            <h3>On-Page SEO</h3>
            <p>Optimize your website content to rank higher in search engines.</p>
          </div>
          <div className="service">
            <h3>Technical SEO</h3>
            <p>Fix technical issues that might be hindering your SEO success.</p>
          </div>
          <div className="service">
            <h3>Link Building</h3>
            <p>Get quality backlinks to boost your domain authority.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
