import React from 'react';
import './hero.css'; // Ensure this path is correct
import heroBg from '../assets/images/hero-bg.jpg'; // Correct path to import

const Hero = () => {
    return (
        <section className="hero" id="home" style={{ backgroundImage: `url(${heroBg})` }}>
            <div className="container">
                <div className="hero-text">
                    <h2>Grow Your Business with Expert SEO Solutions</h2>
                    <p>We help businesses in London & Manchester dominate search engines and grow their online presence.</p>
                    <a href="#contact" className="cta">Get a Free Consultation</a>
                </div>
            </div>
        </section>
    );
};

export default Hero;
